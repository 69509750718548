<template>
<div :key="componentKey">
  <OpeningVideo/>
  <div v-if="!isMobile()">
      <div class="page page-hp" @wheel="wheelMainCarouselHP">
          <full-page class="slider-block-hp" ref="fullpageHP" :options="options" id="fullpage">
            <div class="section">
              <div class="slide slider-item">
                <!--Section 1-->
                <div class="page-section section-first">
    
                  <div class="page-section-col">
                      <div class="logo-bandeau-white">
                          <div class="img-block-brush">
                            <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-1.png"' alt="brush 1" class="logo-brush" width="487" height="264">
                          </div>
                          <div class="logo-block">
                            <h1 style="display: none;">{{$t('nameSite')}}</h1>
                            <img :src='this.$pathprod+"images/logo-rhapsody.svg"' alt="Logo-rhapsody" width="356" height="60">
                            <h2 class="logo-subtext">{{$t('sloganSite')}}</h2>
                          </div>
                      </div>
                  </div>

                  <div class="scroll-right">
                    <a href="javascript:;" class="btn btn-white btn-scroll" v-on:click="scrollToNext">  
                      <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </a>
                  </div>
                </div>
                <!--/Section 1-->
              </div>

              <div class="slide slider-item">
                  <!--Section chiffre key-->
                  <div class="page-section section-key-number" :class="{'increment': increment}">
                    <div class="page-section-col">
                      <div class="block-key-number" @wheel="wheelIntersection" :class="{'stopP': stopP}">
                        <div class="key-number-list">
                            <div class="key-number-item">
                              <p class="key-number">
                                <number
                                  animationPaused
                                  ref="number1"
                                  :from="100"
                                  :to="43500"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>
                                m<span class="m2">2</span></p>
                              <p class="key-desc">{{$t('chiffreKey')['0']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number">
                                <number
                                  animationPaused
                                  ref="number2"
                                  :from="0"
                                  :to="5000"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/> 
                                <br>à 
                                <number
                                  animationPaused
                                  ref="number3"
                                  :from="0"
                                  :to="6000"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>
                                m<span class="m2">2</span></p>
                              <p class="key-desc">{{$t('chiffreKey')['1']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number">
                                <number
                                  animationPaused
                                  ref="number4"
                                  :from="0"
                                  :to="7200"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>
                              m<span class="m2">2</span></p>
                              <p class="key-desc">{{$t('chiffreKey')['2']}} <br> {{$t('chiffreKey')['3']}} m2 {{$t('chiffreKey')['4']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number">
                              <number
                                  animationPaused
                                  ref="number5"
                                  :from="0"
                                  :to="3796"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>  
                                  pers.
                              </p>
                              <p class="key-desc">{{$t('chiffreKey')['5']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number">
                              <number
                                  animationPaused
                                  ref="number6"
                                  :from="0"
                                  :to="310"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>
                              m<span class="m2">2</span></p>
                              <p class="key-desc">{{$t('chiffreKey')['6']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number">
                              <number
                                  animationPaused
                                  ref="number7"
                                  :from="0"
                                  :to="5000"
                                  :format="theFormat"
                                  :duration="2"
                                  easing="Power1.easeOut"/>
                              m<span class="m2">2</span></p>
                              <p class="key-desc">{{$t('chiffreKey')['7']}} <span class="key-desc-mini ls-04">{{$t('chiffreKey')['8']}} <br class="only-mb"> {{$t('chiffreKey')['9']}} {{$t('chiffreKey')['10']}}</span></p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-number-text"><span>373</span> {{$t('chiffreKey')['11']}}</p>
                              <p class="key-desc">{{$t('chiffreKey')['12']}}</p>
                            </div>

                            <div class="key-number-item">
                              <p class="key-desc">{{$t('chiffreKey')['13']}}</p>
                              <p class="key-number-text"><span>780</span> m<span class="m2">2</span> <br>{{$t('chiffreKey')['14']}}</p>
                            </div>
                        </div>
                      </div>
                      <div class="block-gold">
                        <lottie-animation class="block-brush"
                          ref="brushBlack"
                          :animationData="require('@/assets/lottie/brush-black-key-number.json')"
                          :loop="false"
                          :autoPlay="false"
                          :speed="1"
                        />
                      </div>
                    </div>
                    <div class="page-section-col">
                      <figure>
                        <picture class="loadTemp"> 
                          <source srcset="" :dta-srcset='this.$pathprod+"images/home/rhapsody-hp-img-2-retina.webp"' type="image/webp"> 
                          <img src="" :dta-src='this.$pathprod+"images/home/rhapsody-hp-img-2.jpg"' alt="harmony-hp-img-2" class="bg-key-harmony" width="710" height="900">
                        </picture>
                      </figure>
                    </div>
                  </div>
                  <!--Section chiffre key-->
              </div>

              <div class="slide slider-item">
                  <!--Section 3-->
                  <div class="page-section section-home-content">
                    <div class="img-block-brush"  :class="{'showBrush': showBrush}">
                      <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-4.png"' alt="harmony-hp-brush-4" class="mask-brush" width="422" height="546">
                      <div class="mask-brush-mobile"></div>
                    </div>
                    <div class="img-block-brush-2"  :class="{'showBrush': showBrush}">
                      <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-4.png"' alt="harmony-hp-brush-4" class="mask-brush-2" width="422" height="546">
                      <div class="mask-brush2-mobile"></div>
                    </div>
                    <div class="page-section-col">
                    </div>
                    <div class="page-section-col">
                      <div class="home-content">
                        <div class="home-content-top">
                          <div class="logo-block">
                            <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-3.png"' alt="harmony-hp-brush" class="brush" width="142" height="76">
                            <img :src='this.$pathprod+"images/home/logo-white.svg"' alt="logo-harmony-white" class="logo"  width="177" height="30">
                          </div>
                          <div class="home-top-right">
                            <span class="home-text">{{$t('sloganSite')}}</span>
                          </div>
                        </div>
                        <div class="home-content-bottom">
                          <div class="home-content-text-block">
                            <p class="home-content-text">
                              {{$t('lastSectionHome')['0']}}
                            </p>

                            <p class="home-content-text">
                              {{$t('lastSectionHome')['1']}}
                            </p>
                          </div>
                          <div class="home-content-text-block">
                            <p class="home-content-text">
                              {{$t('lastSectionHome')['2']}}
                            </p>

                            <p class="home-content-text">
                              {{$t('lastSectionHome')['3']}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/Section 3-->
              </div>
            </div>
            
          </full-page>
        <!-- </hooper> -->
      </div>
    </div>
    <div v-else>
      <Homemobile/>
    </div>
</div>
    
    
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import 'hooper/dist/hooper.css';

export default {
  name: 'Home',
  components : {
    OpeningVideo: () => import('@/components/OpeningVideo.vue'),
    Homemobile:() => import('@/components/Homemobile.vue'),
    LottieAnimation
  },
  metaInfo() {
      return { 
          title: "Rhapsody - Accueil",
          meta: [
              { name: 'description', content:  'Saint-Ouen. Derrière ses lignes horizontales, RHAPSODY est un chorus ouvert aux improvisations. 44 000 m2 de bureaux et services.'},
              { property: 'og:title', content: "Rhapsody - Accueil"},
              { property: 'og:site_name', content: 'Rhapsody'},
              { property: 'og:type', content: 'website'},    
          ]
      }
  },
  props: {
    msg: String,
  },
  data: ()=>({
      componentKey: 0,
      scroll: null,
      increment: false,
      showBrushK:false,
      showBrush:false,
      varPropagation: false,
      stopP: false,
      valTop: 0,
      valBottom: 0,
      isMove: false,
      bottomPos_mvEnsemble: null,
      options: {
          licenseKey: 'YOUR_KEY_HEERE',
          scrollingSpeed: 1500,
          lockAnchors: true,
          menu: '#menuPage',
          anchors: ['section-first', 'section-key-number', 'home-content'],
          touchSensitivity: 1,
          loopHorizontal: false,
      },
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('wheel', this.handleWheel);
  },
  beforeDestroy(){
    this.$el.remove();
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('wheel', this.handleWheel);
    this.$el.remove();
  },
  methods:{

    theFormat(number) {
      return this.numberWithSpaces(number.toFixed(0));
    },
    
    playAnimation() {
        this.$refs.number1.play()
        this.$refs.number2.play()
        this.$refs.number3.play()
        this.$refs.number4.play()
        this.$refs.number5.play()
        this.$refs.number6.play()
        this.$refs.number7.play()
    },

    isMobile() {
      if( screen.width <= 1200 ) {
          return true;
      }
      else {
          return false;
      }
    },

    addFormatNumber(){
      let item = document.querySelectorAll('.number-to-animate');
      for(let i = 0; i < item.length; i++){
          item[i].innerText = this.numberWithSpaces(item[i].innerText);
      }
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    scrollToNext(e){
      e.preventDefault();      
      document.querySelector('.slider-block-hp .fp-next').click();
      this.playAnimation();
      this.appearLottieAnmi();
    },

    /*Fonction beginAnimationBrush pour la section 3*/
     beginAnimationBrushKeyNumber(){
       this.showBrushK = true;
     },

    /*Fonction beginAnimationBrush pour la section 3*/
     beginAnimationBrushLastSection(){
       this.showBrush = true;
     },

     appearLottieAnmi(){
      this.$refs.brushBlack.play();
    },

     stopBlockPropagation(e){
        e.stopPropagation();
     },
     
    isWheel() {
        var time = 0;
        var nav = window.navigator.platform;
        if (nav == "Mac68K" || nav == "MacPPC" || nav == "MacIntel") {
            time = 1500;
        } else {
            time = 1000;
        }

        this.isMove = true;
        setTimeout(() => {
            this.isMove = false;
        }, time);
    },

     wheelIntersection(e){
        e.stopPropagation();
        let keyNumberBlock = document.querySelector('.block-key-number');
        if(this.isMove){
            return false
        }else{
            if(e.deltaY < 0){
              this.isWheel()
              if(keyNumberBlock.scrollTop === 0){
                document.querySelector('.slider-block-hp .fp-prev').click();
              }
            }
            else{
              this.isWheel()
              var endScreen = (Math.floor(keyNumberBlock.scrollTop))
              var screen_bottom = (Math.floor(keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight)) 
              var old_value = endScreen
              var tmp_value = old_value

              old_value = this.bottomPos_mvEnsemble
              this.bottomPos_mvEnsemble = tmp_value
 
              if(endScreen >= screen_bottom || old_value == this.bottomPos_mvEnsemble && old_value != 0){
                document.querySelector('.slider-block-hp .fp-next').click();
              }
              else{
                this.stopBlockPropagation(e);
              }
            }
        }
     },

    wheelMainCarouselHP(e){
      if(e.deltaY < 0){
          document.querySelector('.slider-block-hp .fp-prev').click();
      }
      else{
        document.querySelector('.slider-block-hp .fp-next').click();
        //Launch animation increase number
        this.playAnimation()
        this.appearLottieAnmi();
      }
    },
  },
  mounted () {
    document.body.classList.remove('removeHiddenOverflow');

    /*Diffère tous les images*/
    setTimeout(function(){
        let loadImgSource = document.querySelectorAll('.loadTemp source');
        let loadImgImg = document.querySelectorAll('.loadTemp img');
        for(let i = 0; i < loadImgSource.length; i++){
            loadImgSource[i].setAttribute('srcset',loadImgSource[i].getAttribute('dta-srcset'));
            loadImgImg[i].setAttribute('src',loadImgImg[i].getAttribute('dta-src'));
            loadImgImg[i].setAttribute('srcset',loadImgImg[i].getAttribute('dta-srcset'));
        }
    }.bind(this), 1200);
  },  
}
</script>